import React from "react";
import { CSSTransition } from "react-transition-group";
import * as ReactDOM from 'react-dom';

import Backdrop from '../backdrop/Backdrop';
import './Modal.css';

const ModalOverlay = probs => {
    const content = (
        <div className={`modal ${probs.className}`} style={probs.style}>
            <header className={`modal__header ${probs.headerClass}`}>
                <h2>{probs.header}</h2>
            </header>
            <form onSubmit={probs.onSubmit ? probs.onSubmit : event => event.preventDefault()}>
                <div className={`modal__content ${probs.contentClass}`}>
                    {probs.children}
                </div>
                <footer className={`modal__footer ${probs.footerClass}`}>
                    {probs.footer}
                </footer>
            </form>
        </div>
    );
        return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const Modal = probs => {
    return <React.Fragment>
        {probs.show && <Backdrop onClick={probs.onCancel}></Backdrop>}
        <CSSTransition in={probs.show} mountOnEnter unmountOnExit timeout={200} classNames='modal'>
            <ModalOverlay {...probs}></ModalOverlay>
        </CSSTransition>
    </React.Fragment>
};

export default Modal;