import React, { useState, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import { AuthContext } from './shared/context/auth-context';
import Menus from './menus/pages/Menus'
import Restaurants from './restaurants/pages/Restaurants/Restaurants';
import NewRestaurant from './restaurants/pages/NewRestaurant/NewRestaurant';
import Login from './users/pages/Login/Login';
import Navigation from './shared/components/navigation/Navigation';
import { useHttpClient } from './shared/hooks/http-hook';
import User from './users/pages/User/User';
import Signup from './users/pages/SignUp/Signup';
import UpdateRestaurant from './restaurants/pages/UpdateRestaurant/UpdateRestaurant';

const App = () => {
  const [name, setName] = useState(JSON.parse(sessionStorage.getItem('user')) ? JSON.parse(sessionStorage.getItem('user')).name : '')
  const [userToken, setUserToken] = useState(JSON.parse(sessionStorage.getItem('user')) ? JSON.parse(sessionStorage.getItem('user')).token : '')
  const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(sessionStorage.getItem('user')) ? true : false)
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const checkAuthentication = async (name = undefined, token = undefined) => {
      const checkIfAuthenticated = async () => {
          try {
            if(name !== undefined && token !== undefined){
              setName(name)
              setUserToken(token)
              sessionStorage.setItem('user', JSON.stringify({'name': name, 'token': token}))
            } 

            const responseData = await sendRequest('https://lunchfinder-api.l1lly.li/api/auth/authenticated', 
              'GET',              
              null,
              {
                'Content-Type': 'application/json',
                'x-access-token': token
              });
            setIsLoggedIn(responseData.authenticated) 
          } catch (err) {
              console.log(err);
          }
      };
      checkIfAuthenticated();
  };

  const logout = useCallback(() => {
    sessionStorage.removeItem('user')
    setIsLoggedIn(false)
  }, []);

  let routes;
  if (isLoggedIn) {
    routes = (
      <Routes>
        <Route path="/" element={<Menus></Menus>}></Route>
        <Route path="/restaurants" element={<Restaurants></Restaurants>}></Route>
        <Route path="/restaurant/new" element={<NewRestaurant></NewRestaurant>}></Route>
        <Route path='/user' element={<User></User>}></Route>
        <Route path='/signup' element={<Signup></Signup>}></Route>
        <Route path='/update/:id' element={<UpdateRestaurant></UpdateRestaurant>}></Route>
        <Route path='*' element={<Navigate to="/"/>}></Route>
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Menus></Menus>}></Route>
        <Route path="/restaurants" element={<Restaurants></Restaurants>}></Route>
        <Route path='/login' element={<Login></Login>}></Route>
        <Route path='*' element={<Navigate to="/"/>}></Route>
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        name: name,
        token: userToken,
        isLoggedIn: isLoggedIn,
        checkAuthentication: checkAuthentication,
        logout: logout
      }}
    >
      <h1 className='center-content title'>LunchFinder</h1>
      <Router>
        <Navigation />
        <main>{routes}</main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
