import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../../../shared/FormElements/input/Input';
import Button from '../../../shared/FormElements/button/Button';
import { VALIDATOR_REQUIRE } from '../../../shared/util/validators';
import { useForm } from '../../../shared/hooks/form-hook';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import ErrorModal from '../../../shared/components/errormodal/ErrorModal';

import './Signup.css'

const Signup = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const navigate = useNavigate();

  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const authSubmitHandler = async event => {
    event.preventDefault();

      try {
        const responseData = await sendRequest(
          'https://lunchfinder-api.l1lly.li/api/auth/signup',
          'POST',
          JSON.stringify({
            name: formState.inputs.name.value,
            password: formState.inputs.password.value
          }),
          {
            'Content-Type': 'application/json',
            'x-access-token': auth.token
          }
        );
        navigate('/')
      } catch (err) {
        console.log(err)
      }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError}></ErrorModal>
      <h1 className='login-title'>New user</h1>
        <form onSubmit={authSubmitHandler} className='signup-form'>
            <Input
              element="input"
              id="name"
              type="text"
              label="Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a name."
              onInput={inputHandler}
            />
          <Input
            element="input"
            id="password"
            type="password"
            label="Password"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a password"
            onInput={inputHandler}
          />
          <Button type="submit" disabled={!formState.isValid}>
           Add new user
          </Button>
        </form>
    </React.Fragment>
  );
};

export default Signup;
