import React, {useContext} from "react";
import {useNavigate} from 'react-router-dom';

import Input from "../../../shared/FormElements/input/Input";
import { VALIDATOR_REQUIRE, VALIDATOR_URL } from "../../../shared/util/validators";
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Button from "../../../shared/FormElements/button/Button";
import { AuthContext } from "../../../shared/context/auth-context";
import ErrorModal from "../../../shared/components/errormodal/ErrorModal";

import "./NewRestaurant.css";

const NewRestaurant = () => {
    const auth = useContext(AuthContext);

    const {isLoading, error, sendRequest, clearError} = useHttpClient();
    const [formState, inputHandler] = useForm({
        name:{
            value: '',
            isValid: false
        },
        url:{
            value: '',
            isValid: false
        },
        regex: {
            value: '',
            isValid: true
        }
    }, false);

    const navigate = useNavigate();

    const placeSubmitHandler = async (event) => {
        event.preventDefault();
        try {
          const requestBody = {
            name: formState.inputs.name.value,
            url: formState.inputs.url.value,
            regex: formState.inputs.regex.value
          };
      
          await sendRequest(
            'https://lunchfinder-api.l1lly.li/api/restaurants/new',
            'POST',
            JSON.stringify(requestBody),
            {
              'Content-Type': 'application/json',
              'x-access-token': auth.token,
            }
          );
          navigate('/restaurants');
        } catch (error) {
          auth.logout();
        }
      };

    const formIsValid = formState.inputs.name.value && formState.inputs.url.value;
      
    return (
    <React.Fragment>
        <ErrorModal error={error} onClear={clearError}></ErrorModal>
        <h1 className="new-restaurant-title">Add a new restaurant</h1>
        <form className="new-restaurant-form" onSubmit={placeSubmitHandler}>
            <Input id="name" element="input" type="text" label="Name of the restaurant" validators={[VALIDATOR_REQUIRE()]} errorText="Please enter a name" onInput={inputHandler}></Input>
            <Input id="url" type="text" element="input" label="Url for the menu (Format: https://example.com)" validators={[VALIDATOR_REQUIRE(), VALIDATOR_URL()]} errorText="Please enter a valid url" onInput={inputHandler}></Input>
            <Input id="regex" element="input" type="text" label="Regex to extract the menus (optional)" validators={[]} errorTex="" onInput={inputHandler} initialTouched={true}></Input>
            <Button type="submit" disabled={!formIsValid}>Add restaurant</Button>
        </form>
    </React.Fragment>
    );
}

export default NewRestaurant;
