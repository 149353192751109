import React, { useContext } from "react";
import { AuthContext } from "../../../shared/context/auth-context";
import { useNavigate } from "react-router-dom";

import './User.css';
import Button from "../../../shared/FormElements/button/Button";

const User = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const logoutHandler = async () => {
        auth.logout();
    }

    const signUpHandler = () => {
        navigate('/signup');
    }

    return (
        <React.Fragment>
            <div className="center-content">
                <h1>Name: {auth.name}</h1>
                <div className="button-container">
                    <Button inverse onClick={signUpHandler}>Add new user</Button>
                    <Button onClick={logoutHandler}>Logout</Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default User;
