import React, { useState, useEffect } from "react";
import Button from "../../../shared/FormElements/button/Button";
import Modal from "../../../shared/components/modal/Modal";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { getISOWeek } from 'date-fns';

import './RandomRestaurant.css';

const RandomRestaurant = props => {
    const [restaurantName, setRestaurantName] = useState('');
    const [restaurantUrl, setRestaurantUrl] = useState('');
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const RandomRestaurantHandler = async () => {
        try {
            const responseData = await sendRequest('https://lunchfinder-api.l1lly.li/api/restaurants/random');
            if(responseData.restaurant.name === 'Prizzi'){
                const currentDate = new Date();
                const weekNumber = getISOWeek(currentDate);
                setRestaurantUrl(responseData.restaurant.url.replace('??', weekNumber));
            }
            setRestaurantName(responseData.restaurant.name);
        } catch (err) {
            console.log(err);
        }
    }

    const onClear = () => {
        setRestaurantName('');
        setRestaurantUrl('');
    }

    return (
        <React.Fragment>
            <Modal
                onCancel={onClear}
                header={'Random Restaurant'}
                show={restaurantName !== ''}
                footer={<Button onClick={onClear}>Close</Button>}
            >
                <a href={restaurantUrl} target="_blank">{restaurantName}</a>
            </Modal>
            <Button onClick={RandomRestaurantHandler} random_restaurant><h2 className="button-text">Random Restaurant</h2></Button>
        </React.Fragment>
    )
}

export default RandomRestaurant;
