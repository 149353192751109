import React from "react";
import { getISOWeek } from 'date-fns';

import RestaurantItem from "../RestaurantItem/RestaurantItem";
import "./RestaurantsList.css";

const RestaurantList = props => {
    if (!props.items || props.items.length === 0) {
        return (
            <div className="center">
                <h2>No restaurants found</h2>
            </div>
        );
    }


    
    return (
        <React.Fragment>
          <ul className="restaurant">
            {props.items.map((restaurant) => {
              if (restaurant.name === 'Prizzi') {
                const currentDate = new Date();
                const weekNumber = getISOWeek(currentDate);
                restaurant.url = restaurant.url.replace('??', weekNumber);
              }
    
              return (
                <RestaurantItem
                  key={restaurant._id}
                  id={restaurant._id}
                  name={restaurant.name}
                  url={restaurant.url}
                  regex={restaurant.regex}
                />
              );
            })}
          </ul>
        </React.Fragment>
      );
    };

export default RestaurantList;
