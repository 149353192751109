import React from "react";
import Card from "../../../shared/components/card/Card";
import { NotificationsActive } from '@mui/icons-material';

import './MenuItem.css'

const MenuItem = probs => {
    const menuName = probs.name;

    if(menuName.toLowerCase().includes('lasagne')){
        return (
            <Card className="card lasagne-alarm-card">
                <li className="user-item">
                    <div className="user-item__info">
                        <NotificationsActive fontSize="large" />
                        <h2 className="lasagne-alarm-name">{probs.name}</h2>
                        {probs.price && (
                            <h3 className="lasagne-alarm-price">{probs.price} CHF</h3>
                        )}
                    </div>
                </li>    
            </Card>
        );
    }
    
    return (
        <Card className="card">
            <li className="user-item">
                <div className="user-item__info">
                    <h2>{probs.name}</h2>
                    {probs.price && (
                        <h3>{probs.price} CHF</h3>
                    )}  
                </div>
            </li>    
        </Card>
    );
};

export default MenuItem;