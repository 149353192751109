import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";

import "./Navigation.css";

const Navigation = probs => {
    const auth = useContext(AuthContext);

    return ( 
    <ul className="nav-links">
        <li>
            <NavLink to={"/"}>Menus</NavLink>
        </li>
        <li>
            <NavLink to={"/restaurants"}>Restaurants</NavLink>
        </li>
        { auth.isLoggedIn && (<li>
            <NavLink to={"/restaurant/new"}>Add a new restaurant</NavLink>
        </li>)}
        { auth.isLoggedIn && (<li>
            <NavLink to={"/user"}>User</NavLink>
        </li>)}
        { !auth.isLoggedIn && (
        <li>
            <NavLink to={'/login'}>Login</NavLink>
        </li>)}
    </ul>)
}

export default Navigation

