import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import RestaurantList from "../../components/RestaurantsList/RestaurantsList";
import RandomRestaurant from "../../components/RandomRestaurant/RandomRestaurant";

const Restaurants = () => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedRestaurants, setLoadedRestaurants] = useState([]);

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const responseData = await sendRequest('https://lunchfinder-api.l1lly.li/api/restaurants/');
                setLoadedRestaurants(responseData.restaurants);
            } catch (err) {
                console.log(err);
            }
        };
        fetchRestaurants();
    }, [sendRequest]);

    return (
        <React.Fragment>
            <div className="center-content">
                <h1 className="restaurant-title">Restaurants</h1>
                    <RandomRestaurant></RandomRestaurant>
                    <RestaurantList items={loadedRestaurants}></RestaurantList>
                </div>
        </React.Fragment>
    );
};

export default Restaurants;
