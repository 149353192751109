import React from "react";
import MenuItem from "../MenuItem/MenuItem";

import './MenusList.css';

const MenusList = props => {
    if (!props.items || Object.keys(props.items).length === 0) {
        return (
            <div className="center">
                <h2>No menus found</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
            <ul className="menus-list">
                {Object.entries(props.items).map(([restaurant, items]) => (
                    <div key={restaurant}>
                        <h1 className="restaurant-title">{restaurant}</h1>
                        <div className="menu-items-container">
                            {items.map(menu => (
                                <MenuItem className="menu" key={menu._id} id={menu._id} name={menu.menu} price={menu.price ? menu.price : null} restaurant={menu.restaurant}/>
                            ))}
                        </div>
                    </div>
                ))}
            </ul>
        </React.Fragment>
    );
};

export default MenusList;
