import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import Input from "../../../shared/FormElements/input/Input";
import { VALIDATOR_REQUIRE, VALIDATOR_URL } from "../../../shared/util/validators";
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Button from "../../../shared/FormElements/button/Button";
import { AuthContext } from "../../../shared/context/auth-context";
import { useParams } from "react-router-dom";
import ErrorModal from "../../../shared/components/errormodal/ErrorModal";

import "./UpdateRestaurant.css";

const UpdateRestaurant = () => {
    const auth = useContext(AuthContext);
    const [restaurant, setRestaurant] = useState('')
    const restaurantId = useParams().id;

    const {isLoading, error, sendRequest, clearError} = useHttpClient();

    const [formState, inputHandler, setFormData] = useForm({
        name:{
            value: '',
            isValid: false
        },
        url:{
            value: '',
            isValid: false
        },
        regex: {
            value: '',
            isValid: false
        }
    }, false);

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const responseData = await sendRequest('https://lunchfinder-api.l1lly.li/api/restaurants/' + restaurantId);
                setRestaurant(responseData.restaurant);
                setFormData(
                    {
                      name: {
                        value: responseData.restaurant.name,
                        isValid: true
                      },
                      url: {
                        value: responseData.restaurant.url,
                        isValid: true
                      },
                      regex: {
                        value: responseData.restaurant.regex,
                        isValid: true
                      },
                    },
                    true
                  );
            } catch (err) {
                auth.logout()
            }
        };
        fetchMenus();
    }, [sendRequest, restaurantId]);

    const navigate = useNavigate();

    const placeSubmitHandler = async event => {
        event.preventDefault();
        try{
            await sendRequest('https://lunchfinder-api.l1lly.li/api/restaurants/update/' + restaurantId, 'PUT', JSON.stringify({
                name: formState.inputs.name.value,
                url: formState.inputs.url.value,
                regex: formState.inputs.regex.value,
            }),{'Content-Type': 'application/json',
                'x-access-token': auth.token
            }
            ); 
            navigate('/restaurants');
        }catch(error){
            auth.logout()
        }
    }

    const formIsValid = formState.inputs.name.value && formState.inputs.url.value;

    return (
    <React.Fragment>
        <ErrorModal error={error} onClear={clearError}></ErrorModal>
        {restaurant && (
            <React.Fragment>
            <h1 className="update-restaurant-title">Update restaurant</h1>
            <form className="update-restaurant-form" onSubmit={placeSubmitHandler}>
                <Input id="name" element="input" type="text" label="Name of the restaurant" validators={[VALIDATOR_REQUIRE()]} errorText="Please enter a name" onInput={inputHandler} initialValue={restaurant.name} initialValid={true}></Input>
                <Input id="url" type="text" element="input" label="Url for the menu (Format: https://example.com)" validators={[VALIDATOR_REQUIRE(), VALIDATOR_URL()]} errorText="Please enter a valid url" onInput={inputHandler} initialValue={restaurant.url} initialValid={true}></Input>
                <Input id="regex" element="input" type="text" label="Regex to extract the menus (optional)" validators={[]} errorText="" onInput={inputHandler} initialValue={restaurant.regex} initialValid={true}></Input>
                <Button type="submit" disabled={!formIsValid}>Update</Button>
            </form>
            </React.Fragment>
        )}
    </React.Fragment>
    );
}

export default UpdateRestaurant;
