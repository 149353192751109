import React, {useContext, useState} from "react";
import Modal from "../../../shared/components/modal/Modal";
import Card from "../../../shared/components/card/Card";
import { AuthContext } from "../../../shared/context/auth-context";
import { useNavigate } from "react-router-dom";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import ErrorModal from "../../../shared/components/errormodal/ErrorModal";
import { EditTwoTone, Delete } from '@mui/icons-material';

import "./RestaurantItem.css";
import Button from "../../../shared/FormElements/button/Button";

const RestaurantItem = probs => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const {isLoading, error, sendRequest, clearError} = useHttpClient();
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const deleteHandler = async event => {
        setShowConfirmModal(false);
        event.preventDefault();
        try{
            await sendRequest('https://lunchfinder-api.l1lly.li/api/restaurants/delete/' + probs.id, 'DELETE', null,
            {
                'Content-Type': 'application/json',
                'x-access-token': auth.token
            }
            ); 
            window.location.reload()
            navigate('/restaurants');
        }catch(err){
            auth.logout();
        }
    }

    const updateHandler = () => {
        navigate('/update/' + probs.id)
    }

    const showDeleteWarningHandler = () => {
        setShowConfirmModal(true);
    };

    const cancelDeleteWarningHandler = () => {
        setShowConfirmModal(false);
    };

    return (
        <React.Fragment>
        <ErrorModal error={error} onClear={clearError}></ErrorModal>
        <Card>
            <li className="user-item">
                <div className="user-item__info">
                    <h1>{probs.name}</h1>
                    <a href={probs.url} target="_blank">Link to menu</a>
                    { auth.isLoggedIn && (
                        <div>
                            { probs.regex !== '' && (
                            <h2>Regex: {probs.regex}</h2>
                            )}
                            <div className="buttons">
                                <Button inverse onClick={updateHandler}><EditTwoTone></EditTwoTone></Button>
                                <Button danger onClick={showDeleteWarningHandler}><Delete/></Button>
                                <Modal show={showConfirmModal} onCancel={cancelDeleteWarningHandler} header="Confirm to delete the restaurant" footerClass="place-item__modal-actions" footer={<React.Fragment>
                                    <Button inverse onClick={cancelDeleteWarningHandler}>CANCEL</Button>
                                    <Button onClick={deleteHandler}>DELETE</Button>
                                    </React.Fragment>}>
                                    <p>Do you want to delete this restaurant?</p>
                                </Modal>
                            </div>
                        </div>
                    )}
                </div>
            </li>    
        </Card>
        </React.Fragment>
    );
};

export default RestaurantItem;