import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import MenusList from "../components/MenusList/MenusList";

import './Menus.css';

const Menus = () => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedMenus, setLoadedMenus] = useState([]);

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const responseData = await sendRequest('https://lunchfinder-api.l1lly.li/api/menus/');
                setLoadedMenus(responseData.menus);
            } catch (err) {
                console.log(err);
            }
        };
        fetchMenus();
    }, [sendRequest]);

    const currentDate = new Date().toISOString().split('T')[0];

    let todayMenus = loadedMenus.filter(item => item.date === currentDate);
    todayMenus = todayMenus.concat(loadedMenus.filter(item => !item.date))

    let groupedData = todayMenus.reduce((groups, item) => {
        const { restaurant } = item;
        
        if (!groups[restaurant]) {
            groups[restaurant] = [];
        }
        
        groups[restaurant].push(item);
        
        return groups;
    }, {});

    return (
        <React.Fragment>
            <div className="center-content">
                <MenusList items={groupedData} />
            </div>
        </React.Fragment>
    );
};

export default Menus;
